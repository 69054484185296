/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/eam/service-descontracturante.jpg";
import team2 from "assets/images/eam/servicio-relajante.jpg";
import team3 from "assets/images/eam/servicio-reductivo.jpg";
import team4 from "assets/images/eam/servicio-drenaje.jpg";
import team5 from "assets/images/eam/servicio-facial.jpg";
import team6 from "assets/images/eam/tratamientos-rejuvenecimiento.png";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      pb={8}
      style={{ background: "linear-gradient(195deg, #51a8a3, #357773)" }}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Nuestros servicios
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              ---
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Masaje Descontracturante"
                position={{ color: "info", label: "Tratamientos Corporales - Masoterapia" }}
                description="Es un masaje manual de intensidad profunda que busca disminuir o disolver molestias, 
                tensiones o contracturas musculares, ocasionadas por estrés, ejercicio y/o movimientos mal ejecutados. 
                Este puede ser realizado en cuerpo completo o un área específica."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Masaje de Relajación"
                position={{ color: "info", label: "Tratamientos Corporales - Masoterapia" }}
                description="Es un Masaje Manual en el cual se realizan movimientos lentos y pausados, 
                que como su nombre lo indica permiten otorgar relajación a la persona, generando bienestar físico y emocional, 
                mejorando además nuestro estado de ánimo. "
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="Reductivos"
                position={{ color: "info", label: "Tratamientos Corporales" }}
                description="Nuestros tratamientos reductivos constan de Radiofrecuencia y/o Cavitación, Masaje reductivo manual y electroestimulación."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Drenaje Linfático Manual"
                position={{ color: "info", label: "Tratamientos Corporales" }}
                description="El Drenaje linfático Manual es un conjunto de maniobras suaves, rítmicas y lentas que facilitan la circulación de la linfa."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team5}
                name="Rejuvenecimiento Facial"
                position={{ color: "info", label: "Tratamientos Faciales" }}
                description="Consta de tratamiento de limpieza básica + Radiofrecuencia facial, que estimula el colágeno mejorando la apariencia de la piel y atenuando líneas de expresión."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team6}
                name="Limpieza Facial Profunda"
                position={{ color: "info", label: "Tratamientos Faciales" }}
                description="Realizamos limpieza facial completa que incluye limpieza, tonificación, extracción de comedones y nutrición, además de peeling ultrasónico y máscara de luz led."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
