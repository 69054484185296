/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

function Newsletter() {
  return (
    <MKBox component="section" pt={1} my={3}>
      <Container>
        <Grid container alignItems="center">
          <Grid item sx={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">Comunicate con nosotros</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              Siguenos en nuestras redes sociales, se publican ofertas y promociones.
            </MKTypography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <a href="https://api.whatsapp.com/send?phone=56990073537&amp;text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n">
                  <MKButton variant="gradient" color="info" sx={{ height: "100%", width: "100%" }}>
                    Agenda tu hora via Whatsapp
                  </MKButton>
                </a>
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: "8px" }}>
              <Grid item xs={12}>
                <a href="mailto:contacto@espacioalmanativa.cl">
                  <MKButton variant="gradient" color="info" sx={{ height: "100%", width: "100%" }}>
                    Comunicate con nosotros via Mail
                  </MKButton>
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <iframe
                align="instaEspacioAlmaNativa"
                width="100%"
                height="620"
                src="https://www.instagram.com/p/CKwyDr0pgmR/embed"
                frameBorder="0"
                scrolling="no"
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Newsletter;
