/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import GoogleMapReact from "google-map-react";

// eslint-disable-next-line react/prop-types
// const MyMarker = ({ text, tooltip }) => (
//   <div className="circle">
//     <span className="circleText" title={tooltip}>
//       {text}
//     </span>
//   </div>
// );

function Maps() {
  const renderMarkers = (map, maps) => {
    const marker = new maps.Marker({
      position: { lat: -33.013989, lng: -71.551398 },
      map,
      title: "Espacio Alma Nativa",
    });
    return marker;
  };
  const showInMapClicked = () => {
    window.open("https://maps.google.com?q=-33.013989,-71.551398");
  };
  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 1 }}>
            <MKTypography variant="h3">Encuentranos</MKTypography>
          </Grid>
        </Grid>
      </Container>
      <MKBox
        component="section"
        variant="gradient"
        bgColor="dark"
        position="relative"
        pt={3}
        px={{ xs: 0, lg: 0 }}
        mx={-2}
        mt={3}
        style={{ background: "linear-gradient(195deg, #51a8a3, #357773)" }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyAENpAwXvGcejpNGl_7uH9Gb7baYh4PmBU",
            language: "es",
            region: "LA",
          }}
          defaultCenter={{ lat: -33.013989, lng: -71.551398 }}
          defaultZoom={18}
          style={{ minHeight: "50vh", minWidth: "90vw" }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
          onClick={() => showInMapClicked()}
        >
          {/* <MyMarker
          key="espacioalmanativa"
          lat={-33.013989}
          lng={-71.551398}
          text="Espacio Alma Nativa"
          tooltip="Espacio Alma Nativa"
        /> */}
        </GoogleMapReact>
      </MKBox>
    </>
  );
}

export default Maps;
