/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/LandingPages/AboutUs/sections/Information";
import Maps from "pages/LandingPages/AboutUs/sections/Maps";
import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter";
import Team from "pages/LandingPages/AboutUs/sections/Team";
// Routes
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/eam/Banner3.png";
import logoImage from "assets/images/eam/logoBanner.svg";

function AboutUs() {
  return (
    <div style={{ background: "#e4fffd" }}>
      {/* <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          label: "Contactanos",
          color: "default",
        }}
        transparent
        light
      /> */}
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <img alt="LogoBanner" src={logoImage} style={{ minWidth: "150px" }} />
            </MKBox>
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Espacio Alma Nativa
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              Bienvenidos a Espacio Alma Nativa un lugar de relajo y cuidado personal.
            </MKTypography>
            <MKTypography variant="h6" color="white" mt={8} mb={1}>
              Siguenos en
            </MKTypography>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <MKTypography
                component="a"
                variant="body1"
                color="white"
                href="https://www.instagram.com/e.almanativa/?utm_source=ig_embed&ig_rid=15e4c0e2-3c40-470b-991c-50a9943f30bd"
                mr={0}
              >
                <i className="fab fa-instagram" />
              </MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        <Team />
        {/* <Featuring /> */}
        <Newsletter />
        <Maps />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      <div>
        <a
          href="https://api.whatsapp.com/send?phone=56990073537&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Espacio%20Alma%20Nativa%20."
          target="noreferrer"
          style={{
            position: "fixed",
            width: "60px",
            height: "60px",
            bottom: "40px",
            right: "40px",
            backgroundColor: "#25d366",
            color: "#FFF",
            borderRadius: "50px",
            textAlign: "center",
            fontSize: "30px",
            boxShadow: "2px 2px 3px #999",
            zIndex: "100",
          }}
        >
          <i className="fa fa-whatsapp" style={{ marginTop: "16px" }} />
        </a>
      </div>
    </div>
  );
}

export default AboutUs;
